import styles from './VideoPlayer.module.css';
import { useRef, useState, useEffect } from 'react';
import videoFile from '../assets/transcript.mp4';
import { ReactComponent as FullscreenIcon } from '../assets/expand.svg';
import { ReactComponent as PlayButtonIcon } from '../assets/play-button.svg';
import { ReactComponent as PauseButtonIcon } from '../assets/pause.svg';
import { ReactComponent as SpeakerIcon } from '../assets/speaker.svg';
import { ReactComponent as TaylorIcon } from '../assets/triangle.svg';
import { ReactComponent as StarIcon } from '../assets/star.svg';
import React from 'react';

enum ProgressBarFilter {
    TaylorImprovementSuggestions,
    KeyMomentHighlights,
}

enum ProgressBarItemKind {
    Strength,
    Weakness,
    Highlight,
}

export type Progress = {
    offset: number,
    duration: number,
    start?: number,
    kind: ProgressBarItemKind,
    total: number,
    title: string,
    id: string,
}

type VideoPlayerProp = {
    progressBarFilter?: ProgressBarFilter,
    setProgressBarFilter?: React.Dispatch<React.SetStateAction<ProgressBarFilter>>
    selectedItem?: Progress,
    taylorImprovementSuggestionsArray?: Progress[],
    keyMomentHighlightArray?: Progress[],
    setSelectedProgress?: React.Dispatch<React.SetStateAction<Progress | undefined>>,
    selectedProgress?: Progress | undefined,
}

const VideoPlayer = (prop: VideoPlayerProp) => {

    const [ playTimePx, setPlayTimePx ] = useState<number>(0);
    const [ isPlaying, setIsPlaying ] = useState<boolean>(false);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const [width, setWidth] = useState(0);

    const updateWidth = () => {
        if (elementRef.current) {
            setWidth(elementRef.current.offsetWidth);
        }
    };

    useEffect(() => {
        updateWidth(); // Set initial width
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth); // Cleanup on unmount
    }, []);

    useEffect(()=>{
        if(prop.selectedProgress){
            setPlayTimePx(
                prop.selectedProgress.start ? Math.round(
                    prop.selectedProgress.start / prop.selectedProgress.total * width
                ) - 5 : 0
            )
        }
    }, [prop.selectedProgress])

    const renderProgressBarFilters = (array:  Progress[]) => {
        return <div ref={elementRef} className={styles.progressBarContainer}>
            {
                array.map((item) => {
                    const getStyle = (item: Progress) => {
                        switch (item.kind) {
                            case ProgressBarItemKind.Strength:
                                return styles.progressBarItemStrength;
                            case ProgressBarItemKind.Weakness:
                                return styles.progressBarItemWeakness;
                            case ProgressBarItemKind.Highlight:
                                return styles.progressBarItemHighlight;
                            default:
                                return ""
                        }
                    }
                    return <React.Fragment key={item.start}>
                        <div 
                            style={{"width": `${Math.round(item.offset / item.total * width)}px`}}
                            className={styles.progressBarItem}
                        ></div>
                        <div 
                            style={{"width": `${Math.round(item.duration / item.total * width)}px`}}
                            className={`${styles.progressBarItem} ${getStyle(item)}`}
                            onClick={() => prop.setSelectedProgress && prop.setSelectedProgress(item)}
                            // onClick={() => setPlayTimePx(item.start ? Math.round(item.start / item.total * width) - 5 : 0)}
                        ></div>
                    </React.Fragment>
                })
            }
        </div>
    }
        
    return (
        <div className={styles.videoContainer}>
            <video id="myVideo" className={styles.video}>
                <source src={videoFile} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
            <div className={styles.controls}>
                {
                    prop.progressBarFilter === ProgressBarFilter.TaylorImprovementSuggestions && renderProgressBarFilters(
                        prop.taylorImprovementSuggestionsArray ? prop.taylorImprovementSuggestionsArray : []
                    )
                }
                { 
                    prop.progressBarFilter === ProgressBarFilter.KeyMomentHighlights && renderProgressBarFilters(
                        prop.keyMomentHighlightArray ? prop.keyMomentHighlightArray : []
                    )
                }
                <div className={styles.progressBarContainer}>
                    <div className={styles.progressBarTotalDuration}>
                        <div style={{"width": `${playTimePx}px`}} className={styles.progressBarCurrentPosition}></div>
                        <div style={{"left": `${playTimePx}px`}} className={styles.progressBarKnot}></div>
                    </div>
                </div>
                <div className={styles.controlIconContainer}>
                    <div className={styles.controlIconContainerLeftRight}>
                        <div className={styles.controlSubContainer} onClick={() => setIsPlaying(!isPlaying)}>
                            {
                                isPlaying ? <PauseButtonIcon className={styles.controlIcons}/> : <PlayButtonIcon className={styles.controlIcons}/>
                            }
                        </div>
                        <div className={styles.controlSubContainer}>
                            <SpeakerIcon className={styles.controlIcons}/>
                        </div>
                    </div>
                    <div className={styles.controlIconContainerLeftRight}>
                        {
                            prop.progressBarFilter != undefined && <div className={styles.controlSubContainer}>
                                <TaylorIcon
                                    onClick={() => prop.setProgressBarFilter?.(ProgressBarFilter.TaylorImprovementSuggestions)}
                                    style={{ transform: 'rotate(-30deg) scaleY(-1)' }} 
                                    className={prop.progressBarFilter == ProgressBarFilter.TaylorImprovementSuggestions ? `${styles.controlIcons} ${styles.controlIconsWhite}` : styles.controlIcons}
                                />
                            </div>
                        }
                        {
                            prop.progressBarFilter != undefined && <div className={styles.controlSubContainer}>
                                <StarIcon
                                    onClick={() => prop.setProgressBarFilter?.(ProgressBarFilter.KeyMomentHighlights)}
                                    className={prop.progressBarFilter == ProgressBarFilter.KeyMomentHighlights ? `${styles.controlIcons} ${styles.controlIconsWhite}` : styles.controlIcons}
                                />
                            </div>
                        }
                        <div className={styles.controlSubContainer}>
                            <FullscreenIcon 
                                className={styles.controlIcons}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {
    VideoPlayer,
    ProgressBarFilter,
    ProgressBarItemKind,
};