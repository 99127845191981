import styles from './TaylorFeedbackSuggestion.module.css';

import { ReactComponent as ImprovementOpportunityIcon } from '../../../assets/setting.svg';
import { ReactComponent as StrengthIcon } from '../../../assets/thumbs-up.svg';
import { ProgressBarItemKind } from '../../../VideoPlayer/VideoPlayer';

import { ReactComponent as ArticulateValuePropositionIcon } from '../../../assets/diamond.svg';
import { ReactComponent as PotentialObjectionsIcon } from '../../../assets/geometry.svg';
import { ReactComponent as CustomerStoryIcon } from '../../../assets/open-book.svg';
import { ReactComponent as SuggestedNextStepsIcon } from '../../../assets/next.svg';
import { ReactComponent as PotentialPainPointsIcon } from '../../../assets/notification.svg';
import { ReactComponent as DiscoveryQuestionIcon } from '../../../assets/loupe.svg';
import { ContentCardType } from '../../../ContentCard/ContentCard';
import { Progress } from '../../../VideoPlayer/VideoPlayer';

type ContentCardKindHeaderProp = {
    progressBarFilterKind: ProgressBarItemKind,
    cardType: ContentCardType,
}

const ContentCardKindHeader = ({cardType}: ContentCardKindHeaderProp) => {
    if(cardType === ContentCardType.DiscoveryQuestion){
        return <div className={styles.contentCardSectionHeader}>
            <DiscoveryQuestionIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardSectionName}> Discovery Question </div>
        </div>
    }
    if(cardType === ContentCardType.PotentialPainPoints){
        return <div className={styles.contentCardSectionHeader}> 
            <PotentialPainPointsIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardSectionName}> Potential Pain Points </div>
        </div>
    }
    if(cardType === ContentCardType.ArticulateValueProposition){
        return <div className={styles.contentCardSectionHeader}>
            <ArticulateValuePropositionIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardSectionName}> Articulate value proposition </div>
        </div>
    }
    if(cardType === ContentCardType.CustomerStories){
        return <div className={styles.contentCardSectionHeader}>
            <CustomerStoryIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardSectionName}> Customer stories </div>
        </div>
    }
    if(cardType === ContentCardType.PotentialObjections){
        return <div className={styles.contentCardSectionHeader}> 
            <PotentialObjectionsIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardSectionName}> Handling Objections </div>
        </div>
    }
    return <div className={styles.contentCardSectionHeader}> 
        <SuggestedNextStepsIcon className={styles.contentCardIcon}/>
        <div className={styles.contentCardSectionName}> Suggested next steps </div>
    </div>
}

type TaylorImprovementSuggestionHeaderProp = {
    progressBarFilterKind: ProgressBarItemKind,
    cardType: ContentCardType,
}

const TaylorImprovementSuggestionHeader = (prop: TaylorImprovementSuggestionHeaderProp) => {
    return (
        <div className={styles.taylorImprovementSuggestionHeader}>
            { 
                prop.progressBarFilterKind === ProgressBarItemKind.Weakness && <div className={styles.taylorImprovementSuggestionKindHeader}>
                    <div>Improvement Opportunity</div>
                    <ImprovementOpportunityIcon className={styles.improvementIcon}/>
                </div>
            }
            {
                prop.progressBarFilterKind === ProgressBarItemKind.Strength && <div className={styles.taylorImprovementSuggestionKindHeader}>
                    <div>Strength</div>
                    <StrengthIcon className={styles.improvementIcon}/>
                </div>
            }
            <ContentCardKindHeader 
                progressBarFilterKind={prop.progressBarFilterKind}
                cardType={prop.cardType} 
            />
        </div>
    )
}


type TaylorImprovementSuggestionProp = {
    progressBarFilterKind: ProgressBarItemKind,
    selectedProgress?: Progress | undefined,
}

const TaylorImprovementSuggestion = (prop: TaylorImprovementSuggestionProp) => {
    const classes = [
        styles.taylorImprovementSuggestionContainer, 
        prop.selectedProgress && prop.selectedProgress.kind === ProgressBarItemKind.Strength && styles.taylorImprovementSuggestionStrength,
    ].filter(Boolean).join(' ');
    return <div className={classes}>
        <TaylorImprovementSuggestionHeader 
            progressBarFilterKind={prop.selectedProgress ? prop.selectedProgress.kind : ProgressBarItemKind.Strength}
            cardType={ContentCardType.PotentialObjections}
        />
        <div className={styles.taylorImprovementSuggestionContentContainer}>
            <div className={styles.taylorImprovementSuggestionContent}>
                Detailed description of how the seller could have handled the content card better or handled the content card well
            </div>
        </div>
    </div>
}

export {
    TaylorImprovementSuggestion
}