import styles from './PreCallHeader.module.css';
import profile from '../../../assets/profile.png';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg';
import { dateFormatter, hourFormatter } from '../../SelectMeetingPage/VideoMeetingItem';

type PreCallHeaderProp = {

}

const PreCallHeader = (prop: PreCallHeaderProp) => {

        
    return (
        <div className={styles.contentCardDisplayHeader}>
            <div className={styles.overallDetails}>
                <div className={styles.companyDetail}>
                    <div className={styles.companyName}>ACME Corp</div>
                    <div className={styles.industrySegment}>B2C software • Enterprise</div>
                </div>
                <div className={styles.callDetails}>
                        <CalendarIcon className={styles.icon}/>
                        <div className={styles.dateDetails}>{dateFormatter.format(1731264612000)}</div>
                        <ClockIcon className={styles.icon}/>
                        <div className={styles.dateDetails}>{hourFormatter.format(1731264612000)}</div>
                </div>
            </div>
            <div className={styles.participants}>
                <div className={styles.participant}>
                    <img className={styles.participantAvatar} src={profile} alt={`avatar`} />
                    <div className={styles.participantDetail}>
                        <div className={styles.participantName}>Victor Hugo</div>
                        <div className={styles.participantTitle}>Cofounder and CEO</div>      
                    </div>
                </div>
                <div className={styles.participant}>
                    <img className={styles.participantAvatar} src={profile} alt={`avatar`} />
                    <div className={styles.participantDetail}>
                        <div className={styles.participantName}>Peter Edwards</div>
                        <div className={styles.participantTitle}>Cofounder and CTO</div>      
                    </div>
                </div>
            </div>
        </div>
    );
};

export {
    PreCallHeader
};