import styles from './VideoMeetingItem.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import profile from '../../assets/profile.png';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as ClockIcon } from '../../assets/clock.svg';

export type VideoMeeting = {
    id: string,
    title: string,
    date: number,
    endTime: number,
    participants: string[],
    location: "Zoom",
    meetingLink: string,
    zoomMeetingId: string,
    zoomPasscode: string,
    notifications: string[],
    description: string,
}

export type VideoMeetingItemProps = {
    item: VideoMeeting,
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
});

const hourFormatter = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit'
});

const VideoMeetingItem = ({item}: VideoMeetingItemProps) => {

    const navigate = useNavigate();
    const { callId } = useParams();


    const handleItemClick = (itemId: string) => {
        navigate(`/call/${itemId}`);
    };

    return (
        <div onClick={() => handleItemClick(item.id)} className={`${styles.videMeetingItem} ${item.date <= 1727354300000 ? styles.videMeetingItemActive : ''} ${item.id === callId ? styles.videMeetingItemSelected : ''}`}>
            <div className={styles.videMeetingItemHeader}>
                <img className={styles.videMeetingItemIcon} src={profile} alt={`avatar`} />
                <div className={styles.videMeetingItemTitle}>{item.title}</div>       
            </div>
            <div className={styles.videMeetingItemDateTimeContainer}>
                <CalendarIcon className={styles.videMeetingDateTimeIcon}/>
                <div className={styles.videMeetingItemDate}>{dateFormatter.format(item.date)}</div>
            </div>
            <div className={styles.videMeetingItemDateTimeContainer}>
                <ClockIcon className={styles.videMeetingDateTimeIcon}/>
                <div className={styles.videMeetingItemDate}>{hourFormatter.format(item.date)}</div>
            </div>
            <div className={styles.videMeetingParticipants}>
                {
                    item.participants.map((email) => (
                        <div key={email}> {email}</div>
                    ))
                }
            </div>
            <div className={styles.videMeetingItemDescription}>{item.description}</div>
        </div>
    )

}

export {
    VideoMeetingItem,
    dateFormatter,
    hourFormatter,
};