import { ModalWrapper } from "../ModalWrapper/ModalWrapper"
import { ContentCardChampions } from "./ContentCardChampions"
import styles from './ContentCardModal.module.css'
import { ReactComponent as ArticulateValuePropositionIcon } from '../assets/diamond.svg';
import { ReactComponent as PotentialObjectionsIcon } from '../assets/geometry.svg';
import { ReactComponent as CustomerStoryIcon } from '../assets/open-book.svg';
import { ReactComponent as SuggestedNextStepsIcon } from '../assets/next.svg';
import { ReactComponent as PotentialPainPointsIcon } from '../assets/notification.svg';
import { ReactComponent as DiscoveryQuestionIcon } from '../assets/loupe.svg';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ContentCardType } from '../ContentCard/ContentCard';
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";

type ContentCardModalProp = {
    onClose: () => void,
}

type ContentCardModalHeaderProp = {
    cardType: ContentCardType,
}

const ContentCardModalHeader = ({cardType}: ContentCardModalHeaderProp) => {
    if(cardType === ContentCardType.DiscoveryQuestion){
        return <div className={styles.contentCardTypeHeader}>
            <DiscoveryQuestionIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardModalName}> Discovery Question </div>
        </div>
    }
    if(cardType === ContentCardType.PotentialPainPoints){
        return <div className={styles.contentCardTypeHeader}> 
            <PotentialPainPointsIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardModalName}> Potential Pain Points </div>
        </div>
    }
    if(cardType === ContentCardType.ArticulateValueProposition){
        return <div className={styles.contentCardTypeHeader}>
            <ArticulateValuePropositionIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardModalName}> Articulate value proposition </div>
        </div>
    }
    if(cardType === ContentCardType.CustomerStories){
        return <div className={styles.contentCardTypeHeader}>
            <CustomerStoryIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardModalName}> Customer stories </div>
        </div>
    }
    if(cardType === ContentCardType.PotentialObjections){
        return <div className={styles.contentCardTypeHeader}> 
            <PotentialObjectionsIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardModalName}> Handling Objections</div>
        </div>
    }
    return <div className={styles.contentCardTypeHeader}> 
        <SuggestedNextStepsIcon className={styles.contentCardIcon}/>
        <div className={styles.contentCardModalName}> Suggested next steps </div>
    </div>
}

type HandlingExampleProp = {
    title: string
}

const HandlingExample = (props: HandlingExampleProp) => {
    return <div className={styles.handlingExampleContainer}>
        <div className={styles.handlingExampleTitle}>
            {props.title}
        </div>
        <div className={styles.handlingExampleTalkTrack}>
            Very extensive response for handling objections in a detailed talk track, handling any objections with care 
        </div>
        <VideoPlayer/>
    </div>

}

const ContentCardModal = (props: ContentCardModalProp) => {

    return <ModalWrapper onClose={props.onClose}>
        <div className={styles.contentCardModalHeader}>
            <div className={styles.contentCardTitleContainer}>
                <div>
                    <div className={styles.contentCardName}>Company vs Competitior</div>
                    <ContentCardModalHeader cardType={ContentCardType.PotentialObjections}/>
                </div>
                <CloseIcon className={styles.closeIcon} onClick={props.onClose}/>
            </div>
            <ContentCardChampions/>
        </div>
        <div className={styles.exampleContainer}>
            <HandlingExample title="Advantage X"/>
            <HandlingExample title="Advantage Y"/>
            <HandlingExample title="Advantage Z"/>
        </div>
    </ModalWrapper>

}

export {
    ContentCardModal
}