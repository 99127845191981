import { ContentCard } from '../../../ContentCard/ContentCard';
import { VideoPlayer } from '../../../VideoPlayer/VideoPlayer';
import styles from './LiveCallContainer.module.css';
// import { ZoomApp } from './ZoomApp';


const LiveCallContainer = () => {

    return <div className={styles.liveCallContainer}>
        <div className={styles.container}>
            <ContentCard 
                data={
                    {
                        lastSeen: 1730772238, 
                        isActive: false,
                        title: "Company vs Competitor",
                        adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                        talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
                    }
                }
                updateContentCard={ () => console.log("Update")}
            />
            {/* <ZoomApp/> */}
            <VideoPlayer/>
        </div>
    </div>
}

export {
    LiveCallContainer
}