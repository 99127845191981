import { useState } from 'react';
import { ContentCard, ContentCardType, ContentCardData } from '../../../ContentCard/ContentCard';
import styles from './PreCallContainer.module.css';

import { ContentCardSelection } from './ContentCardSelection';
import { PreCallHeader } from './PreCallHeader';


const PreCallContainer = () => {

    const [contentCardType, setContentCardType] = useState<ContentCardType>(ContentCardType.DiscoveryQuestion);
    const [contentMap, setContentMap] = useState<Map<ContentCardType, ContentCardData[]>>(new Map([
        [ContentCardType.DiscoveryQuestion, [
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
        ]],
        [ContentCardType.PotentialPainPoints, [
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
        ]],
        [ContentCardType.ArticulateValueProposition, [
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
        ]],
        [ContentCardType.CustomerStories, [
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
        ]],
        [ContentCardType.PotentialObjections, [
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
        ]],
        [ContentCardType.SuggestedNextSteps, [
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
            {
                lastSeen: 1730772238, 
                isActive: true,
                title: "Company vs Competitor",
                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
            },
        ]],
    ]));

    // Higher-order function that returns a function to update a specific card
    const createUpdateContentCard = (cardType: ContentCardType, index: number) => {
        return (newData: Partial<ContentCardData>) => {
            setContentMap((prevMap) => {
                const newMap = new Map(prevMap);
                const currentArray = newMap.get(cardType);
                if (currentArray) {
                    const updatedArray = currentArray.map(obj => ({ ...obj }))
                    if(index < updatedArray.length){
                        updatedArray[index] =  { ...updatedArray[index], ...newData } ;
                    }
                    newMap.set(cardType, updatedArray);
                }
                return newMap;
            });
        };
    };

    return <div className={styles.preCallContainer}>
        <PreCallHeader/>
        <ContentCardSelection
            contentCardMap={contentMap}
            setContentCardType={setContentCardType}
            selectedContentCardType={contentCardType}
        />
        <div className={styles.contentCardDisplay}>
            <div className={styles.contentCardsContainer}>
                {
                (contentMap.get(contentCardType) || []).map((data, index)=>(
                    <ContentCard 
                        data={data} 
                        updateContentCard={createUpdateContentCard(contentCardType, index)}
                        key={index}
                    />
                ))
                }
            </div>
        </div>
    </div>
}

export {
    PreCallContainer
}