import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import WorkspacePage from './Pages/WorkspacePage';
// import PipelinePage from './Pages/PipelinePage';
// import { PipelineView, WorkspaceView } from './Pages/Routes';
import SideNavBarPage from './Pages/SideNavBarPage';
import SelectMeetingPage from './Pages/SelectMeetingPage/SelectMeetingPage';
import { ContentCardLibraryPage } from './Pages/ContentCardLibrary/ContentCardLibraryPage';
import { CallPage } from './Pages/CallPage/CallPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div> Sorry, your requested site does not exist</div>,
    children: [
      {
        path: "/",
        element: <SideNavBarPage />,
        children: [
          {
            path: "calls",
            element: <SelectMeetingPage />,
          },
          {
            path: "call/:callId",
            element: <CallPage />,
          },
          {
            path: "library",
            element: <ContentCardLibraryPage />,
          },
        ]
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
