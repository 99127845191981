import styles from './ContentCardSelection.module.css';

import { ReactComponent as ArticulateValuePropositionIcon } from '../../../assets/diamond.svg';
import { ReactComponent as PotentialObjectionsIcon } from '../../../assets/geometry.svg';
import { ReactComponent as CustomerStoryIcon } from '../../../assets/open-book.svg';
import { ReactComponent as SuggestedNextStepsIcon } from '../../../assets/next.svg';
import { ReactComponent as PotentialPainPointsIcon } from '../../../assets/notification.svg';
import { ReactComponent as DiscoveryQuestionIcon } from '../../../assets/loupe.svg';
import { ReactComponent as CardIcon } from '../../../assets/card.svg';
import { ContentCardData, ContentCardType } from '../../../ContentCard/ContentCard';

type ContentCardSectionProp = {
    cardType: ContentCardType,
    cards: ContentCardData[],
    selected: boolean,
    setContentCardType: React.Dispatch<React.SetStateAction<ContentCardType>>,
}

const ContentCardSection = ({cardType, cards, selected, setContentCardType}: ContentCardSectionProp) => {
    if(cards.length === 0){
        return <div className={styles.contentCardSection}>
            <ContentCardHeader cardType={cardType}/>
        </div>
    }
    return <div 
        className={selected ? `${styles.contentCardSection} ${styles.contentCardSectionSelected}` : `${styles.contentCardSection} ${styles.contentCardSectionActive}`}
        onClick={() => setContentCardType(cardType)}
        >
        <div className={styles.contentCardHeaderContainer}>
            <ContentCardHeader cardType={cardType}/>
        </div>
        <div className={styles.contentCardsCollectionContainer}>
            <div className={styles.contentCardsCollection}>
                {
                    cards.map((data, index) => (
                        <CardIcon 
                            className={data.isActive ? `${styles.contentCardIcon} ${styles.contentCardIconActive}` : styles.contentCardIcon } 
                            key={index}/>
                    ))
                }
            </div>
        </div>
    </div>
}

type ContentCardHeaderProp = {
    cardType: ContentCardType,
}

const ContentCardHeader = ({cardType}: ContentCardHeaderProp) => {
    if(cardType === ContentCardType.DiscoveryQuestion){
        return <div className={styles.contentCardSectionHeader}>
            <DiscoveryQuestionIcon className={styles.contentCardSectionIcon}/>
            <div className={styles.contentCardSectionName}> Discovery Question </div>
        </div>
    }
    if(cardType === ContentCardType.PotentialPainPoints){
        return <div className={styles.contentCardSectionHeader}> 
            <PotentialPainPointsIcon className={styles.contentCardSectionIcon}/>
            <div className={styles.contentCardSectionName}> Potential Pain Points </div>
        </div>
    }
    if(cardType === ContentCardType.ArticulateValueProposition){
        return <div className={styles.contentCardSectionHeader}>
            <ArticulateValuePropositionIcon className={styles.contentCardSectionIcon}/>
            <div className={styles.contentCardSectionName}> Articulate value proposition </div>
        </div>
    }
    if(cardType === ContentCardType.CustomerStories){
        return <div className={styles.contentCardSectionHeader}>
            <CustomerStoryIcon className={styles.contentCardSectionIcon}/>
            <div className={styles.contentCardSectionName}> Customer stories </div>
        </div>
    }
    if(cardType === ContentCardType.PotentialObjections){
        return <div className={styles.contentCardSectionHeader}> 
            <PotentialObjectionsIcon className={styles.contentCardSectionIcon}/>
            <div className={styles.contentCardSectionName}> Potential Objections </div>
        </div>
    }
    return <div className={styles.contentCardSectionHeader}> 
        <SuggestedNextStepsIcon className={styles.contentCardSectionIcon}/>
        <div className={styles.contentCardSectionName}> Next steps </div>
    </div>
}

type ContentCardSelectionProp = {
    selectedContentCardType: ContentCardType,
    setContentCardType: React.Dispatch<React.SetStateAction<ContentCardType>>,
    contentCardMap: Map<ContentCardType, ContentCardData[]>

}

const ContentCardSelection = ({selectedContentCardType, setContentCardType, contentCardMap}: ContentCardSelectionProp) => {
    return (
        <div className={styles.contentcardSection}>
            <div className={styles.contentCards}>
                <ContentCardSection 
                    cardType={ContentCardType.DiscoveryQuestion} 
                    cards={(contentCardMap.get(ContentCardType.DiscoveryQuestion) || [])} 
                    selected={selectedContentCardType == ContentCardType.DiscoveryQuestion}
                    setContentCardType={setContentCardType}
                />
                <ContentCardSection 
                    cardType={ContentCardType.PotentialPainPoints}
                    cards={(contentCardMap.get(ContentCardType.PotentialPainPoints) || [])} 
                    selected={selectedContentCardType == ContentCardType.PotentialPainPoints}
                    setContentCardType={setContentCardType}
                />
                <ContentCardSection
                    cardType={ContentCardType.ArticulateValueProposition}
                    cards={(contentCardMap.get(ContentCardType.ArticulateValueProposition) || [])} 
                    selected={selectedContentCardType == ContentCardType.ArticulateValueProposition}
                    setContentCardType={setContentCardType}
                />
                <ContentCardSection
                    cardType={ContentCardType.CustomerStories}
                    cards={(contentCardMap.get(ContentCardType.CustomerStories) || [])} 
                    selected={selectedContentCardType == ContentCardType.CustomerStories}
                    setContentCardType={setContentCardType}
                />
                <ContentCardSection 
                    cardType={ContentCardType.PotentialObjections}
                    cards={(contentCardMap.get(ContentCardType.PotentialObjections) || [])} 
                    selected={selectedContentCardType == ContentCardType.PotentialObjections}
                    setContentCardType={setContentCardType}
                />
                <ContentCardSection 
                    cardType={ContentCardType.SuggestedNextSteps} 
                    cards={(contentCardMap.get(ContentCardType.SuggestedNextSteps) || [])} 
                    selected={selectedContentCardType == ContentCardType.SuggestedNextSteps}
                    setContentCardType={setContentCardType}
                />
            </div>
        </div>
    );
};

export {
    ContentCardSelection
};