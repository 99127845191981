import { useParams } from 'react-router-dom';
import styles from './EmailDraft.module.css';
import { SetStateAction, useState } from 'react';
  
const EmailDraft = () => {

    const { accountId, interactionId } = useParams();

    const [email, setEmail] = useState(`Dear Jane,

Thanks for taking the time to chat earlier today. I wanted to quickly summarize our discussion and next steps.
        
Core Business Initiatives & Goals: 
• Increase billable hours efficiency by 20%
• Reduce research time by 30% through AI-powered legal assistance
        
Challenges (Why Do Anything):
• Maintaining efficiency across diverse practice areas
• Balancing innovation with ethical considerations in AI adoption
• Streamlining complex multi-jurisdictional research processes

How Harvey AI Can Help (Why Harvey):
• AI-powered due diligence for tech M&A deals, potentially reducing review time by 50%
• FDA regulatory document analysis with 99% accuracy
• Cross-border transaction research assistance, covering 100+ jurisdictions

Next Steps:
• I'll send over our AI in Legal Practice white paper
• You'll confirm a date (w/c [insert date]) for a comprehensive demo with practice area leaders
• We'll schedule an AI readiness assessment for Latham & Watkins

Looking forward to exploring how we can help with these challenges. Feel free to reach out if you need anything before our next chat.

Best,
[Your Name]
Harvey AI`);

    const handleEmailChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setEmail(e.target.value);
    };
        
    return (
        <div className={styles.emailDraftContainer}>
            <textarea 
                value={email} 
                onChange={handleEmailChange}
                className={styles.emailDraft}
            />
        </div>
    );
};

export {
    EmailDraft
};