import './App.css';
import styles from './App.module.css';
import { Outlet } from "react-router-dom";
import { onAuthStateChanged, signInWithPopup, User } from "firebase/auth";
import { useEffect, useState } from 'react';
import { auth } from './firebaseConfig';
import { SAMLAuthProvider } from "firebase/auth";

function App() {

  const [user, setUser] = useState<User | null>(null);

  const signInWithSAML = async () => {
    const provider = new SAMLAuthProvider("saml.taylor");
    signInWithPopup(auth, provider);
  };

  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
          setUser(user);
      });
      return () => unsubscribe();
  }, []);

  // if(user === null){
  //   return <div onClick={signInWithSAML} className={styles.appContainer}>
  //     <div>Sign In Here</div>
  //   </div>
  // }

  return (
    <div className={styles.appContainer}>
        <Outlet/>
    </div>
  );
}

export default App;
