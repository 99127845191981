import { useState } from 'react';
import { ReactComponent as EyeIcon } from '../assets/open-eye.svg';
// import { Slider } from '../Slider/Slider';
import styles from './ContentCard.module.css';
import { ReactComponent as CheckIcon } from '../assets/check.svg';
import { ContentCardModal } from './ContentCardModal';
import React from 'react';


enum ContentCardType {
    DiscoveryQuestion,
    PotentialPainPoints,
    ArticulateValueProposition,
    CustomerStories,
    PotentialObjections,
    SuggestedNextSteps,
}

export type ContentCardData = {
    isActive: boolean;
    lastSeen: number;
    title: string,
    adantages: string[],
    talkTrack: string,
}

type ContentCardProp = {
    data: ContentCardData;
    updateContentCard: (newData: Partial<ContentCardData>) => void;
}

const ContentCard = (props: ContentCardProp) => {

    const [isContentCardModalVisible, setIsContentCardModalVisible] = useState<boolean>(false)

    // const dateFormatter = new Intl.DateTimeFormat('en-US', {
    //     month: 'short',
    //     day: 'numeric',
    //     year: 'numeric',
    // });

    return <React.Fragment>
        {
            isContentCardModalVisible && <ContentCardModal onClose={() => setIsContentCardModalVisible(false)}/>
        }
        <div onClick={() => props.updateContentCard({isActive: !props.data.isActive})} className={`${styles.contentCard} ${ props.data.isActive ? styles.contentCardActive : styles.contentCardInActive}`}>

            <div className={styles.contentCardIcons}>
                {/* <div className={styles.lastSeenContainer}>
                    <EyeIcon className={styles.contentCardIcon}/>
                    <div>{dateFormatter.format(props.data.lastSeen)}</div>
                </div> */}
                {/* <Slider
                    initialState={props.data.isActive}
                    handleToggle={(checked) => props.updateContentCard({isActive: checked})}
                /> */}
            </div>
            <div className={styles.header}>
                <div className={styles.contentCardName}>
                    { props.data.title }
                </div>
                {
                    props.data.isActive && <CheckIcon className={styles.checkMarkIcon}/>
                }
            </div>
            <ul className={styles.contentCardUl}>
                {
                    props.data.adantages.map((text) => 
                        <li className={styles.contentCardLi}> {text}</li>
                    )
                }
            </ul>
            <div className={styles.contentCardTalkTrack}>
                { props.data.talkTrack }
            </div>
            <div className={styles.detailsContainer} onClick={() => setIsContentCardModalVisible(true) }>
                <div className={styles.details}>
                    Details
                </div>
            </div>
        </div>
    </React.Fragment>
}

export {
    ContentCard,
    ContentCardType,
}