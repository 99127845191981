
import styles from "./FollowUpContainer.module.css";
import { EmailDraft } from "./EmailDraft";
import { useState } from "react";
import { SalesforceUpdates } from "./SalesforceUpdates";


enum FollowUpSelection {
    EMAIL,
    MEDDPICC,
}

const FollowUpContainer = () => {   
    
    const [selectedFollowUp, setSelectedFollowUp] = useState<FollowUpSelection>(FollowUpSelection.EMAIL)
    
    return (
        <div className={styles.container}>
            <div className={styles.selector}>
                <div 
                    className={`${styles.selectorItem} ${selectedFollowUp === FollowUpSelection.EMAIL ? styles.selectorItemSelected : ''}`}
                    onClick={() => setSelectedFollowUp(FollowUpSelection.EMAIL)}
                >
                    Drafted Follow-up E-Mail
                </div>
                <div 
                    className={`${styles.selectorItem} ${selectedFollowUp === FollowUpSelection.MEDDPICC ? styles.selectorItemSelected : ''}`}
                    onClick={() => setSelectedFollowUp(FollowUpSelection.MEDDPICC)}
                >
                    Salesforce MEDDPICC Updates 
                </div>
            </div>
            {
                selectedFollowUp === FollowUpSelection.EMAIL && <EmailDraft/>
            }
            {
                selectedFollowUp === FollowUpSelection.MEDDPICC && <SalesforceUpdates/>
            }
        </div>
    );
};

export {
    FollowUpContainer
};