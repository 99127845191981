import styles from './TimestampSelection.module.css';

import { ReactComponent as TaylorIcon } from '../../../assets/triangle.svg';
import { ReactComponent as StarIcon } from '../../../assets/star.svg';
import { ProgressBarFilter, ProgressBarItemKind } from '../../../VideoPlayer/VideoPlayer';
import { ReactComponent as ImprovementOpportunityIcon } from '../../../assets/setting.svg';
import { ReactComponent as StrengthIcon } from '../../../assets/thumbs-up.svg';
import { Progress } from '../../../VideoPlayer/VideoPlayer';

type TimestampSelectionProp = {
    progressBarFilter: ProgressBarFilter,
    setProgressBarFilter: React.Dispatch<React.SetStateAction<ProgressBarFilter>>,
    taylorImprovementSuggestionsArray: Progress[],
    keyMomentHighlightArray: Progress[],
    setSelectedProgress: React.Dispatch<React.SetStateAction<Progress | undefined>>,
    selectedProgress: Progress | undefined,
}

type StrengthAndWeaknessIconProp = {
    progressBarFilterKind: ProgressBarItemKind,
}


const StrengthAndWeaknessIcon = (prop: StrengthAndWeaknessIconProp) => {
    if(prop.progressBarFilterKind === ProgressBarItemKind.Strength){
        return  <StrengthIcon className={styles.strengthIcon}/>
    }
    if(prop.progressBarFilterKind === ProgressBarItemKind.Weakness){
        return  <ImprovementOpportunityIcon className={styles.improvementIcon}/>
    }
    return null;

}

const TimestampSelection = (prop: TimestampSelectionProp) => {
    return <div className={styles.timestampSelectionContainer}>
        <div className={styles.timestampSelectionHeader}>
            <div 
                style={{"borderTopLeftRadius": "20px"}}
                onClick={() => prop.setProgressBarFilter(ProgressBarFilter.TaylorImprovementSuggestions)}
                className={prop.progressBarFilter == ProgressBarFilter.TaylorImprovementSuggestions ? `${styles.timestampSelectionHeaderItem} ${styles.timestampSelectionHeaderItemActive}` : styles.timestampSelectionHeaderItem}
            >
                Taylor Insights
                <TaylorIcon style={{ transform: 'rotate(-30deg) scaleY(-1)' }} className={styles.icon}/>
            </div>
            <div 
                style={{"borderTopRightRadius": "20px"}}
                onClick={() => prop.setProgressBarFilter(ProgressBarFilter.KeyMomentHighlights)}
                className={prop.progressBarFilter == ProgressBarFilter.KeyMomentHighlights ? `${styles.timestampSelectionHeaderItem} ${styles.timestampSelectionHeaderItemActive}` : styles.timestampSelectionHeaderItem}
            >
                Key Moments
                <StarIcon className={styles.icon}/>
            </div>
        </div>
        {
            prop.progressBarFilter === ProgressBarFilter.TaylorImprovementSuggestions && <div className={styles.timestampSelectionContent}>
                {
                    prop.taylorImprovementSuggestionsArray.map((item: Progress) => {
                        return <div 
                            className={prop.selectedProgress && item.id === prop.selectedProgress.id ? `${styles.timestampSelectionContentItem} ${styles.timestampSelectionContentItemHighlighted}` : styles.timestampSelectionContentItem} 
                            key={item.id} 
                            onClick={()=> prop.setSelectedProgress && prop.setSelectedProgress(item)}
                        >
                        <StrengthAndWeaknessIcon progressBarFilterKind={item.kind}/>
                        <div>{item.title}</div>
                    </div>
                    })
                }
            </div>
        }
        {
            prop.progressBarFilter === ProgressBarFilter.KeyMomentHighlights && <div className={styles.timestampSelectionContent}>
                {
                    prop.keyMomentHighlightArray.map((item: Progress) => {
                        return <div className={styles.timestampSelectionContentItem} key={item.id}>
                        <div>{item.title}</div>
                    </div>
                    })
                }
            </div>
        }
    </div>
}

export {
    TimestampSelection
}