import { ReactNode } from 'react';
import style from './ModalWrapper.module.css';

type ModalWrapperProp = {
    onClose: () => void,
    children: ReactNode,
}

const ModalWrapper = (prop: ModalWrapperProp) => {
    return (
        <div className={style.modal}>
            <div className={style.modalContent}>
                {prop.children}
            </div>
        </div>
    );
};

export {
    ModalWrapper
};