import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAcmITmMzzzLdDBtGWYi7cS7cA5O9WHMBU",
    authDomain: "taylor-437019.firebaseapp.com",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {
    app,
    auth,
};