import { VideoMeeting } from "./VideoMeetingItem";

export const zoomMeetingMockData: VideoMeeting[] = [
    {
      "id": "meeting-1",
      "title": "Sales Call with Acme Corp",
      "date": 1727254800000,
      "endTime": 1727258400000,
      "participants": ["sales@example.com", "james.smith@acmecorp.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/12345678901",
      "zoomMeetingId": "12345678901",
      "zoomPasscode": "abc123",
      "notifications": ["email", "popup"],
      "description": "Sales call with James Smith from Acme Corp to discuss product offerings and pricing."
    },
    {
      "id": "meeting-2",
      "title": "Sales Pitch to BetaTech",
      "date": 1727262000000,
      "endTime": 1727267400000,
      "participants": ["sales@example.com", "susan.jones@betatech.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/23456789012",
      "zoomMeetingId": "23456789012",
      "zoomPasscode": "def456",
      "notifications": ["email", "popup"],
      "description": "Sales pitch to Susan Jones from BetaTech, showcasing our latest product line."
    },
    {
      "id": "meeting-3",
      "title": "Sales Call with Gamma Industries",
      "date": 1727340000000,
      "endTime": 1727343600000,
      "participants": ["sales@example.com", "michael.brown@gammaindustries.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/34567890123",
      "zoomMeetingId": "34567890123",
      "zoomPasscode": "ghi789",
      "notifications": ["popup"],
      "description": "Discussion with Michael Brown from Gamma Industries regarding bulk orders."
    },
    {
      "id": "meeting-4",
      "title": "Sales Negotiation with Delta Solutions",
      "date": 1727422200000,
      "endTime": 1727424000000,
      "participants": ["sales@example.com", "emma.davis@deltasolutions.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/45678901234",
      "zoomMeetingId": "45678901234",
      "zoomPasscode": "jkl012",
      "notifications": ["popup"],
      "description": "Negotiation of terms with Emma Davis from Delta Solutions for ongoing partnership."
    },
    {
      "id": "meeting-5",
      "title": "Sales Call with Echo Enterprises",
      "date": 1727509200000,
      "endTime": 1727512800000,
      "participants": ["sales@example.com", "john.evans@echoenterprises.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/56789012345",
      "zoomMeetingId": "56789012345",
      "zoomPasscode": "mno345",
      "notifications": ["email", "popup"],
      "description": "Sales call with John Evans from Echo Enterprises to present our service offerings."
    },
    {
      "id": "meeting-6",
      "title": "Product Demo for Foxtrot Ltd",
      "date": 1727599200000,
      "endTime": 1727602800000,
      "participants": ["sales@example.com", "linda.wilson@foxtrotltd.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/67890123456",
      "zoomMeetingId": "67890123456",
      "zoomPasscode": "pqr678",
      "notifications": ["popup"],
      "description": "Demoing our latest products for Linda Wilson from Foxtrot Ltd."
    },
    {
      "id": "meeting-7",
      "title": "Sales Call with GammaSoft",
      "date": 1727676000000,
      "endTime": 1727679600000,
      "participants": ["sales@example.com", "oliver.martin@gammsoft.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/78901234567",
      "zoomMeetingId": "78901234567",
      "zoomPasscode": "stu901",
      "notifications": ["email"],
      "description": "Call with Oliver Martin from GammaSoft to discuss potential collaboration."
    },
    {
      "id": "meeting-8",
      "title": "Sales Call with Horizon Tech",
      "date": 1727760600000,
      "endTime": 1727766000000,
      "participants": ["sales@example.com", "rachel.hall@horizontech.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/89012345678",
      "zoomMeetingId": "89012345678",
      "zoomPasscode": "vwx234",
      "notifications": ["popup"],
      "description": "Sales meeting with Rachel Hall from Horizon Tech to explore new product opportunities."
    },
    {
      "id": "meeting-9",
      "title": "Sales Call with IronWorks Corp",
      "date": 1727842800000,
      "endTime": 1727846400000,
      "participants": ["sales@example.com", "thomas.baker@ironworkscorp.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/90123456789",
      "zoomMeetingId": "90123456789",
      "zoomPasscode": "yzb567",
      "notifications": ["email", "popup"],
      "description": "Sales discussion with Thomas Baker from IronWorks Corp to discuss pricing and delivery options."
    },
    {
      "id": "meeting-10",
      "title": "Sales Demo for Juno Solutions",
      "date": 1727923200000,
      "endTime": 1727930400000,
      "participants": ["sales@example.com", "laura.lee@junosolutions.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/01234567890",
      "zoomMeetingId": "01234567890",
      "zoomPasscode": "abc890",
      "notifications": ["popup"],
      "description": "Product demo for Laura Lee at Juno Solutions."
    },
    {
      "id": "meeting-11",
      "title": "Sales Call with Kappa Dynamics",
      "date": 1728002400000,
      "endTime": 1728006000000,
      "participants": ["sales@example.com", "sophia.green@kappadynamics.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/12309876543",
      "zoomMeetingId": "12309876543",
      "zoomPasscode": "xyz098",
      "notifications": ["popup"],
      "description": "Sales call with Sophia Green from Kappa Dynamics to discuss potential business deals."
    },
    {
      "id": "meeting-12",
      "title": "Sales Presentation for Lambda Corp",
      "date": 1728080400000,
      "endTime": 1728085800000,
      "participants": ["sales@example.com", "henry.james@lambdacorp.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/23498765432",
      "zoomMeetingId": "23498765432",
      "zoomPasscode": "bcd321",
      "notifications": ["email"],
      "description": "Presenting products to Henry James from Lambda Corp for an upcoming project."
    },
    {
      "id": "meeting-13",
      "title": "Sales Call with Nexus Group",
      "date": 1728162000000,
      "endTime": 1728165600000,
      "participants": ["sales@example.com", "mia.white@nexusgroup.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/34587654321",
      "zoomMeetingId": "34587654321",
      "zoomPasscode": "cde654",
      "notifications": ["email", "popup"],
      "description": "Discussion with Mia White from Nexus Group to outline sales strategy."
    },
    {
      "id": "meeting-14",
      "title": "Sales Call with Omega Enterprises",
      "date": 1728243600000,
      "endTime": 1728249000000,
      "participants": ["sales@example.com", "william.thomas@omegaenterprises.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/45676543210",
      "zoomMeetingId": "45676543210",
      "zoomPasscode": "def789",
      "notifications": ["email"],
      "description": "Sales call with William Thomas from Omega Enterprises to discuss long-term business plans."
    },
    {
      "id": "meeting-15",
      "title": "Sales Call with Polaris Systems",
      "date": 1728328200000,
      "endTime": 1728331800000,
      "participants": ["sales@example.com", "jackson.king@polarissystems.com"],
      "location": "Zoom",
      "meetingLink": "https://zoom.us/j/56765432109",
      "zoomMeetingId": "56765432109",
      "zoomPasscode": "efg012",
      "notifications": ["email", "popup"],
      "description": "Call with Jackson King from Polaris Systems about upcoming software integration."
    },
    {
        "id": "meeting-16",
        "title": "Sales Call with Quasar Technologies",
        "date": 1728412800000,
        "endTime": 1728416400000,
        "participants": ["sales@example.com", "grace.harris@quasartechnologies.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/67896543210",
        "zoomMeetingId": "67896543210",
        "zoomPasscode": "hij345",
        "notifications": ["email", "popup"],
        "description": "Sales call with Grace Harris from Quasar Technologies to discuss cloud solutions."
    },
    {
        "id": "meeting-17",
        "title": "Sales Call with Red Star Corp",
        "date": 1728499200000,
        "endTime": 1728502800000,
        "participants": ["sales@example.com", "matthew.jones@redstarcorp.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/78987654321",
        "zoomMeetingId": "78987654321",
        "zoomPasscode": "klm456",
        "notifications": ["email", "popup"],
        "description": "Discussion with Matthew Jones from Red Star Corp regarding partnership opportunities."
    },
    {
        "id": "meeting-18",
        "title": "Sales Call with Silverline Solutions",
        "date": 1728585600000,
        "endTime": 1728589200000,
        "participants": ["sales@example.com", "olivia.smith@silverlinesolutions.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/89098765432",
        "zoomMeetingId": "89098765432",
        "zoomPasscode": "nop567",
        "notifications": ["email", "popup"],
        "description": "Sales call with Olivia Smith from Silverline Solutions to explore new services."
    },
    {
        "id": "meeting-19",
        "title": "Sales Call with Titan Dynamics",
        "date": 1728672000000,
        "endTime": 1728675600000,
        "participants": ["sales@example.com", "daniel.johnson@titandynamics.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/12345678911",
        "zoomMeetingId": "12345678911",
        "zoomPasscode": "qrs678",
        "notifications": ["email", "popup"],
        "description": "Call with Daniel Johnson from Titan Dynamics to finalize the deal."
    },
    {
        "id": "meeting-20",
        "title": "Sales Call with Universal Tech",
        "date": 1728758400000,
        "endTime": 1728762000000,
        "participants": ["sales@example.com", "sophia.martin@universaltech.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/23456789123",
        "zoomMeetingId": "23456789123",
        "zoomPasscode": "tuv789",
        "notifications": ["email", "popup"],
        "description": "Sales discussion with Sophia Martin from Universal Tech regarding our new product line."
    },
    {
        "id": "meeting-21",
        "title": "Sales Call with Vertex Solutions",
        "date": 1728844800000,
        "endTime": 1728848400000,
        "participants": ["sales@example.com", "liam.jackson@vertexsolutions.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/34567891234",
        "zoomMeetingId": "34567891234",
        "zoomPasscode": "wxy890",
        "notifications": ["email", "popup"],
        "description": "Sales call with Liam Jackson from Vertex Solutions to discuss service offerings."
    },
    {
        "id": "meeting-22",
        "title": "Sales Call with Yonder Innovations",
        "date": 1728931200000,
        "endTime": 1728934800000,
        "participants": ["sales@example.com", "emma.smith@yonderinnovations.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/45678901245",
        "zoomMeetingId": "45678901245",
        "zoomPasscode": "zab012",
        "notifications": ["email", "popup"],
        "description": "Discussion with Emma Smith from Yonder Innovations about a new project."
    },
    {
        "id": "meeting-23",
        "title": "Sales Call with Zenith Solutions",
        "date": 1729017600000,
        "endTime": 1729021200000,
        "participants": ["sales@example.com", "noah.james@zenithsolutions.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/56789012356",
        "zoomMeetingId": "56789012356",
        "zoomPasscode": "cde345",
        "notifications": ["email", "popup"],
        "description": "Sales call with Noah James from Zenith Solutions to discuss integration options."
    },
    {
        "id": "meeting-24",
        "title": "Sales Call with Alpha Partners",
        "date": 1729104000000,
        "endTime": 1729107600000,
        "participants": ["sales@example.com", "isabella.lee@alphapartners.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/67890123467",
        "zoomMeetingId": "67890123467",
        "zoomPasscode": "fgh456",
        "notifications": ["email", "popup"],
        "description": "Call with Isabella Lee from Alpha Partners regarding potential collaboration."
    },
    {
        "id": "meeting-25",
        "title": "Sales Call with Beta Solutions",
        "date": 1729190400000,
        "endTime": 1729194000000,
        "participants": ["sales@example.com", "alex.martinez@betasolutions.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/78901234578",
        "zoomMeetingId": "78901234578",
        "zoomPasscode": "ijk789",
        "notifications": ["email", "popup"],
        "description": "Sales call with Alex Martinez from Beta Solutions to finalize service details."
    },
    {
        "id": "meeting-26",
        "title": "Sales Call with Cloud Nine Inc.",
        "date": 1729276800000,
        "endTime": 1729280400000,
        "participants": ["sales@example.com", "ava.davis@cloudnineinc.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/89012345689",
        "zoomMeetingId": "89012345689",
        "zoomPasscode": "lmn012",
        "notifications": ["email", "popup"],
        "description": "Sales discussion with Ava Davis from Cloud Nine Inc. regarding cloud services."
    },
    {
        "id": "meeting-27",
        "title": "Sales Call with DataWave Solutions",
        "date": 1729363200000,
        "endTime": 1729366800000,
        "participants": ["sales@example.com", "ryan.wilson@datawavesolutions.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/90123456790",
        "zoomMeetingId": "90123456790",
        "zoomPasscode": "opq345",
        "notifications": ["email", "popup"],
        "description": "Sales call with Ryan Wilson from DataWave Solutions to discuss analytics services."
    },
    {
        "id": "meeting-28",
        "title": "Sales Call with Energy Hub",
        "date": 1729449600000,
        "endTime": 1729453200000,
        "participants": ["sales@example.com", "emily.clark@energyhub.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/01234567891",
        "zoomMeetingId": "01234567891",
        "zoomPasscode": "rst678",
        "notifications": ["email", "popup"],
        "description": "Discussion with Emily Clark from Energy Hub regarding energy solutions."
    },
    {
        "id": "meeting-29",
        "title": "Sales Call with Future Tech",
        "date": 1729536000000,
        "endTime": 1729539600000,
        "participants": ["sales@example.com", "jason.brown@futuretech.com"],
        "location": "Zoom",
        "meetingLink": "https://zoom.us/j/12345678902",
        "zoomMeetingId": "12345678902",
        "zoomPasscode": "uvw123",
        "notifications": ["email", "popup"],
        "description": "Sales call with Jason Brown from Future Tech to explore future collaboration."
    },
    // {
    //     "id": "meeting-30",
    //     "title": "Sales Call with Green Earth Co.",
    //     "date": 1729622400000,
    //     "endTime": 1729626000000,
    //     "participants": ["sales@example.com", "chloe.white@greenearthco.com"],
    //     "location": "Zoom",
    //     "meetingLink": "https://zoom.us/j/23456789003",
    //     "zoomMeetingId": "23456789003",
    //     "zoomPasscode": "xyz456",
    //     "notifications": ["email", "popup"],
    //     "description": "Sales call with Chloe White from Green Earth Co. to discuss sustainability initiatives."
    // }
]
      
  