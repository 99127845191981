import styles from '../Pages.module.css';
import { zoomMeetingMockData } from './SelectMeetingPageData';
import { VideoMeetingItem } from './VideoMeetingItem';


const SelectMeetingPage = () => {

    return (
        <div className={styles.appContent}>
            <div className={styles.gridContainer}>
                <div className={styles.videoSelector}>
                    {
                        zoomMeetingMockData.map((item, index) => (
                            <VideoMeetingItem key={index} item={item}/>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default SelectMeetingPage;