import { useState } from 'react';
import styles from './PostCallContainer.module.css';
import { ContentCard } from '../../../ContentCard/ContentCard';
import { TimestampSelection } from './TimestampSelection';
import { TaylorImprovementSuggestion } from './TaylorFeedbackSuggestion';
import { Transcript } from './Transcipt';
import { Progress, ProgressBarFilter, ProgressBarItemKind, VideoPlayer } from '../../../VideoPlayer/VideoPlayer';


const PostCallContainer = () => {

    const [selectedProgress, setSelectedProgress] = useState<Progress | undefined>(undefined);

    const [ progressBarFilter, setProgressBarFilter ] = useState<ProgressBarFilter>(
        ProgressBarFilter.TaylorImprovementSuggestions
    );

    const addStartTimeToProgressArray = (array: Progress[]) => {
        return array.reduce((acc, num, index) => {
            acc.push({
                ...num,
                start: acc[index - 1] ? (acc[index - 1].start || 0) +  (acc[index - 1].duration || 0) + num.offset : num.offset, 
            });
            return acc;
        }, [] as Progress[]);
    }

    const taylorImprovementSuggestionsArray: Progress[] = addStartTimeToProgressArray([
        {id: "taylorImprovementSuggestionsArray-1", offset: 100, duration: 30, total: 600, kind: ProgressBarItemKind.Weakness, title: "00:30 Rapport too long"},
        {id: "taylorImprovementSuggestionsArray-2", offset: 120, duration: 50, total: 600, kind: ProgressBarItemKind.Strength, title: "00:40 Discovery routine"},
        {id: "taylorImprovementSuggestionsArray-3", offset: 100, duration: 30, total: 600, kind: ProgressBarItemKind.Weakness, title: "00:55 Competition objection"},
        {id: "taylorImprovementSuggestionsArray-4", offset: 50, duration: 20, total: 600, kind: ProgressBarItemKind.Strength, title: "01:45 Warranty objection"},
    ]);

    const keyMomentHighlightArray: Progress[] = addStartTimeToProgressArray([
        {id: "keyMomentHighlightArray-1", offset: 10, duration: 30, total: 600, kind: ProgressBarItemKind.Highlight, title: "00:55 Rapport"},
        {id: "keyMomentHighlightArray-2", offset: 200, duration: 50, total: 600, kind: ProgressBarItemKind.Highlight, title: "01:36 Discovery"},
        {id: "keyMomentHighlightArray-3", offset: 100, duration: 30, total: 600, kind: ProgressBarItemKind.Highlight, title: "01:36 Articulate Value Proposition"},
        {id: "keyMomentHighlightArray-4", offset: 50, duration: 30, total: 600, kind: ProgressBarItemKind.Highlight, title: "01:39 Handling Objections"},
        {id: "keyMomentHighlightArray-5", offset: 50, duration: 30, total: 600, kind: ProgressBarItemKind.Highlight, title: "02:95 Next steps"},
    ]);


    return <div className={styles.postCallContainer}>
        <div className={styles.videoFeedback}>
            <div className={styles.videoPlayerContainer}>
                <VideoPlayer 
                    progressBarFilter={progressBarFilter} 
                    setProgressBarFilter={setProgressBarFilter}
                    taylorImprovementSuggestionsArray={taylorImprovementSuggestionsArray}
                    keyMomentHighlightArray={keyMomentHighlightArray}
                    selectedProgress={selectedProgress}
                    setSelectedProgress={setSelectedProgress}
                />
                <div 
                    className={progressBarFilter === ProgressBarFilter.TaylorImprovementSuggestions ? styles.improvementSuggestion : `${styles.improvementSuggestion} ${styles.improvementSuggestionInactive}`}
                >
                    <ContentCard 
                        data={
                            {
                                lastSeen: 1730772238, 
                                isActive: true,
                                title: "Company vs Competitor",
                                adantages: ["Advantage X", "Advantage Y", "Advantage Z"], 
                                talkTrack: "Very exensive response for handeling objections in a detailed talk track, handeling an objections with care"               
                            }
                        }
                        updateContentCard={ () => console.log("Update")}
                    />
                    <TaylorImprovementSuggestion 
                        progressBarFilterKind={ProgressBarItemKind.Strength}
                        selectedProgress={selectedProgress}

                    />
                </div>
            </div>
            <div className={styles.leftSideBar}>
                <TimestampSelection 
                    progressBarFilter={progressBarFilter} 
                    setProgressBarFilter={setProgressBarFilter}
                    taylorImprovementSuggestionsArray={taylorImprovementSuggestionsArray}
                    keyMomentHighlightArray={keyMomentHighlightArray}
                    selectedProgress={selectedProgress}
                    setSelectedProgress={setSelectedProgress}
                />
                <Transcript/>
            </div>
        </div>
    </div>
}

export {
    PostCallContainer,
}