import styles from './Pages.module.css';
import { Outlet, useNavigate } from "react-router-dom";
import { ReactComponent as MeetingsIcon } from '../assets/call.svg';
import { ReactComponent as ContentCardLibraryIcon } from '../assets/flash-cards.svg';
// import { ReactComponent as UserIcon } from '../assets/user.svg';
import profile from '../assets/profile.png';
import { useCallback, useState } from 'react';
import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig";


const SideNavBarPage = () => {

    const navigate = useNavigate();
    const [ hovered, setHovered] = useState<boolean>(false);

    const logout = useCallback(() => {
        signOut(auth)
    }, [])


    return (
        <div className={styles.appContent}>
            <div 
                className={hovered ? `${styles.navbarContainer} ${styles.navbarContainerHovered}` : styles.navbarContainer 
                }
                onMouseLeave={() => setHovered(false)}
                onMouseEnter={() => setHovered(true)}
            >
                <div className={styles.topNavBarContainer}>
                    <div 
                        className={hovered ? `${styles.navbarContainerItem} ${styles.navbarContainerItemHovered}` : styles.navbarContainerItem }
                        onClick={() => navigate(`/calls`)}
                        >
                        <MeetingsIcon 
                            className={hovered ? `${styles.navbarIcon} ${styles.navbarIconHovered}` : styles.navbarIcon}
                        />
                        <div className={styles.navbarContainerItemTitle}>Meetings</div>
                    </div>
                    <div 
                        className={hovered ? `${styles.navbarContainerItem} ${styles.navbarContainerItemHovered}` : styles.navbarContainerItem }
                        onClick={() => navigate(`/library`)}
                        >
                        <ContentCardLibraryIcon 
                            className={hovered ? `${styles.navbarIcon} ${styles.navbarIconHovered}` : styles.navbarIcon}
                        />
                        <div className={styles.navbarContainerItemTitle}>Your cards</div>
                    </div>
                </div>
                <div 
                    onClick={logout}
                    className={hovered ? `${styles.navbarContainerItem} ${styles.navbarContainerItemHovered}` : styles.navbarContainerItem }
                    >
                    <img className={styles.videMeetingItemIcon} src={profile} alt={`avatar`} />
                    <div className={styles.navbarContainerItemTitle}>Remy Khoung</div>
                </div>
            </div>
            <Outlet/>
        </div>
    );
};

export default SideNavBarPage;