import style from "./ContentCardChampions.module.css"
import { ReactComponent as FirstPlaceIcon } from '../assets/1st-prize.svg';
import { ReactComponent as SecondPlaceIcon } from '../assets/2nd-place.svg';
import { ReactComponent as ThirdPlaceIcon } from '../assets/3rd-place.svg';
import profile from '../assets/profile.png';

enum ChamptionRank {
    FIRST,
    SECOND,
    THIRD,
}

type ContentCardChampionProp = {
    rank: ChamptionRank,
    name: string,
    winRate: number,
}

const ContentCardChampion = (props: ContentCardChampionProp) => {
    return (
        <div className={style.championContainer}>
            <div className={style.championAvatarContainer}>
                <img className={style.championAvatar} src={profile} alt={`avatar`} />
                {
                    props.rank === ChamptionRank.FIRST && <FirstPlaceIcon className={style.placeIcon}/>
                }
                {
                    props.rank === ChamptionRank.SECOND &&<SecondPlaceIcon className={style.placeIcon}/>
                }
                {
                    props.rank === ChamptionRank.THIRD &&<ThirdPlaceIcon className={style.placeIcon}/>
                }
            </div>
            <div className={style.championNameContainer}>
                <div className={style.rankWinRate}>
                    <div className={style.rank}>
                        {
                            props.rank === ChamptionRank.FIRST && "Gold"
                        }
                        {
                            props.rank === ChamptionRank.SECOND && "Silver"
                        }
                        {
                            props.rank === ChamptionRank.THIRD && "Bronze"
                        }
                    </div>
                    <div className={style.winRate}>
                        {`${props.winRate}% success`}
                    </div>
                </div>
                <div className={style.championName}>
                    {props.name}
                </div>
            </div>
        </div>
    )       
    
}

type ContentCardChampionsProp = {}

const ContentCardChampions = (props: ContentCardChampionsProp) => {
    return <div className={style.champions}>
        <ContentCardChampion rank={ChamptionRank.FIRST} name={"Peter Harrison"} winRate={98}/>
        <ContentCardChampion rank={ChamptionRank.SECOND} name={"Ashley Peterson"} winRate={97}/>
        <ContentCardChampion rank={ChamptionRank.THIRD} name={"Shawn Harrison"} winRate={94}/>
    </div>
}

export {
    ContentCardChampions
}