
import { useState } from "react";
import styles from "./SalesforceUpdates.module.css";
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/minus.svg';
import { ReactComponent as CheckIcon } from '../../../assets/check.svg';
import { ReactComponent as WarningIcon } from '../../../assets/warning.svg';
import { ReactComponent as UndoIcon } from '../../../assets/undo.svg';



enum MEDDPICCSelection {
    Metric,
    EconomicBuyer,
    DecisionCriteria,
    DecisionProcess,
    PaperProcess,
    IdentifyPain,
    Champion,
    Competition,
}

type MEDDPICCHeaderProp = {
    kind: MEDDPICCSelection,
    status: SalesforceFieldStatus,
}

const MEDDPICCHeader = (prop: MEDDPICCHeaderProp) => {
    return <div className={styles.MEDDPICCHeader}>
        {
            prop.kind === MEDDPICCSelection.Metric && <div>Metric</div>
        }
        {
            prop.kind === MEDDPICCSelection.EconomicBuyer && <div>Economic Buyer</div>
        }
        {
            prop.kind === MEDDPICCSelection.DecisionCriteria && <div>Decision Critera</div>
        }
        {
            prop.kind === MEDDPICCSelection.DecisionProcess && <div>Decision Process</div>
        }
        {
            prop.kind === MEDDPICCSelection.PaperProcess && <div>Paper Process</div>
        }
        {
            prop.kind === MEDDPICCSelection.IdentifyPain && <div>Identify Pain</div>
        }
        {
            prop.kind === MEDDPICCSelection.Champion && <div>Champion</div>
        }
        {
            prop.kind === MEDDPICCSelection.Competition && <div>Competition</div>
        }
        {
            prop.status === SalesforceFieldStatus.COMPLETE && <CheckIcon className={`${styles.statusIcon} ${styles.completeIcon}`}/>
        }
        {
            prop.status === SalesforceFieldStatus.MISSING && <WarningIcon className={`${styles.statusIcon} ${styles.missingIcon}`}/>
        }
        {
            prop.status === SalesforceFieldStatus.DECISION && <WarningIcon className={`${styles.statusIcon} ${styles.decisionIcon}`}/>
        }
    </div>
}

type SaleforceUpdateHeaderProp = {
    salesforceUpdateStatus: SalesForceUpdateStatus
    setSalesforceStatus: React.Dispatch<React.SetStateAction<SalesForceUpdateStatus>>
    status: SalesforceFieldStatus
    kind: MEDDPICCSelection
}

const SaleforceUpdateHeader = (prop: SaleforceUpdateHeaderProp) => {
    return <div className={styles.salesforceUpdateContainerHeader}>
        <MEDDPICCHeader kind={prop.kind} status={prop.status}/>
        <div className={styles.salesforceUpdateContainerHeaderControls}>
            {
                prop.salesforceUpdateStatus === SalesForceUpdateStatus.DECISION && <div 
                    className={styles.salesforceUpdateContainerHeaderControlsReject}
                    onClick={() => prop.setSalesforceStatus(SalesForceUpdateStatus.REJECTED)}
                >Reject</div>
            }
            {
                prop.salesforceUpdateStatus === SalesForceUpdateStatus.DECISION && <div 
                    className={styles.salesforceUpdateContainerHeaderControlsAccept}
                    onClick={() => prop.setSalesforceStatus(SalesForceUpdateStatus.ACCEPTED)}
                >Accept</div>
            }
            {
                (prop.salesforceUpdateStatus === SalesForceUpdateStatus.ACCEPTED || 
                prop.salesforceUpdateStatus === SalesForceUpdateStatus.REJECTED ) && <div 
                    className={styles.undoIconContainer}
                    onClick={() => prop.setSalesforceStatus(SalesForceUpdateStatus.DECISION)}
                >
                    <UndoIcon className={styles.undoIcon} />
                </div>
            }
            {
                prop.status === SalesforceFieldStatus.COMPLETE && (
                    <div className={styles.salesforceUpdateContainerHeaderControlsCompleted}>Complete</div>
                )
            }
            {
                prop.status === SalesforceFieldStatus.MISSING && (
                    <div className={styles.salesforceUpdateContainerHeaderControlsMissing}>Missing</div>
                )
            }
        </div>
    </div>
}


enum SalesforceFieldKind {
    CURRENT_VALUE,
    OLD_VALUE,
    SUGGESTION,
}

type SalesforceFieldProp = {
    kind: SalesforceFieldKind,
    value: string,
}

const SalesforceField = (prop: SalesforceFieldProp) => {

    if(prop.kind === SalesforceFieldKind.CURRENT_VALUE){
        return <div className={styles.salesforceUpdateTextContainerItem}>
            <div
                className={`${styles.salesforceUpdateTextContainerItemHeader} ${styles.salesforceUpdateTextContainerItemHeaderCurrent}`}
            >
                <div> Current</div>
            </div>
            <div
                className={`${styles.salesforceUpdateTextContainerItemContent} ${styles.salesforceUpdateTextContainerItemContentCurrent}`}
            >
                {prop.value}
            </div>
        </div>
    }

    if(prop.kind === SalesforceFieldKind.OLD_VALUE){
        return <div className={styles.salesforceUpdateTextContainerItem}>
            <div
                className={`${styles.salesforceUpdateTextContainerItemHeader} ${styles.salesforceUpdateTextContainerItemHeaderPrevious}`}
            >
                <div className={styles.iconContainer}> 
                    <MinusIcon className={`${styles.icon} ${styles.iconMinus}`}/>
                </div>
                <div> Current</div>
            </div>
            <div
                className={`${styles.salesforceUpdateTextContainerItemContent} ${styles.salesforceUpdateTextContainerItemContentPrevious}`}
            >
                {prop.value}
            </div>
        </div>
    }

    // prop.kind === SalesforceUpdateTextKind.SUGGESTION
    return <div className={styles.salesforceUpdateTextContainerItem}>
        <div
            className={`${styles.salesforceUpdateTextContainerItemHeader} ${styles.salesforceUpdateTextContainerItemHeaderSuggested}`}
        >
            <div className={styles.iconContainer}> 
                <PlusIcon className={`${styles.icon} ${styles.iconPlus}`}/>
            </div>
            <div>
                Suggested
            </div>
        </div>
        <div
            className={`${styles.salesforceUpdateTextContainerItemContent} ${styles.salesforceUpdateTextContainerItemContentSuggested}`}
        >
            {prop.value}
        </div>
    </div>

}

enum SalesforceFieldStatus {
    COMPLETE,
    MISSING,
    DECISION,
}

enum SalesForceUpdateStatus {
    ACCEPTED,
    REJECTED,
    DECISION,
    NOT_FOUND,
}


type SaleforceUpdateProp = {
    kind: MEDDPICCSelection,
    suggestedValue?: string,
    existingValue?: string,
}

const SaleforceUpdate = (prop: SaleforceUpdateProp) => {

    const [salesforceUpdateStatus, setSalesforceStatus] = useState<SalesForceUpdateStatus>(
        prop.suggestedValue ? SalesForceUpdateStatus.DECISION : SalesForceUpdateStatus.NOT_FOUND
    );

    const completed = salesforceUpdateStatus !== SalesForceUpdateStatus.DECISION && (
        prop.existingValue !== undefined || salesforceUpdateStatus === SalesForceUpdateStatus.ACCEPTED
    )

    const missing = (
        salesforceUpdateStatus === SalesForceUpdateStatus.REJECTED ||  prop.suggestedValue === undefined 
    ) && prop.existingValue === undefined


    const currentStyle = completed
        ? styles.salesforceUpdateContainerCompleted
        : missing
        ? styles.salesforceUpdateContainerMissing
        : styles.salesforceUpdateContainerPending;

    return <div className={`${styles.salesforceUpdateContainer} ${currentStyle}`}>
        <SaleforceUpdateHeader
            salesforceUpdateStatus={salesforceUpdateStatus}
            setSalesforceStatus={setSalesforceStatus}
            status={completed 
                ? SalesforceFieldStatus.COMPLETE 
                : missing 
                ? SalesforceFieldStatus.MISSING
                : SalesforceFieldStatus.DECISION
            }
            kind={prop.kind}
        />
        <div className={styles.salesforceUpdateTextContainer}>
            {
                prop.existingValue && salesforceUpdateStatus === SalesForceUpdateStatus.DECISION && <SalesforceField 
                    kind={SalesforceFieldKind.OLD_VALUE} 
                    value={prop.existingValue}
                />
            }
            {
                prop.suggestedValue && salesforceUpdateStatus === SalesForceUpdateStatus.DECISION && <SalesforceField 
                    kind={SalesforceFieldKind.SUGGESTION} 
                    value={prop.suggestedValue}
                />
            }
            {
                prop.suggestedValue && salesforceUpdateStatus === SalesForceUpdateStatus.ACCEPTED && <SalesforceField 
                    kind={SalesforceFieldKind.CURRENT_VALUE} 
                    value={prop.suggestedValue}
                />
            }
            {
                prop.existingValue && (
                    salesforceUpdateStatus === SalesForceUpdateStatus.REJECTED 
                    || salesforceUpdateStatus === SalesForceUpdateStatus.NOT_FOUND
                ) && <SalesforceField 
                    kind={SalesforceFieldKind.CURRENT_VALUE} 
                    value={prop.existingValue}
                />
            }   
            {
                missing && <div
                    className={`${styles.salesforceUpdateTextContainerItemContent} ${styles.salesforceUpdateTextContainerItemContentPrevious}`}
                >
                    Not found in Salesforce or call recording
                </div>
            }
        </div>
    </div>
}


const SalesforceUpdates = () => {   
    return (
        <div className={styles.container}>
            <SaleforceUpdate
                kind={MEDDPICCSelection.Metric}
                existingValue={"Cost reduction, ease of implementation"}
                suggestedValue={"Cost reduction (25%), ease of implementation (2-week timeline), and compliance requirements"}
            />
            <SaleforceUpdate
                kind={MEDDPICCSelection.EconomicBuyer}
                suggestedValue={"Cost reduction (25%), ease of implementation (2-week timeline), and compliance requirements"}
            />
            <SaleforceUpdate
                kind={MEDDPICCSelection.DecisionCriteria}
                existingValue={"Cost reduction, ease of implementation"}
            />
            <SaleforceUpdate
                kind={MEDDPICCSelection.DecisionProcess}
            />
            <SaleforceUpdate
                kind={MEDDPICCSelection.PaperProcess}
                existingValue={"Cost reduction, ease of implementation"}
                suggestedValue={"Cost reduction (25%), ease of implementation (2-week timeline), and compliance requirements"}
            />
            <SaleforceUpdate
                kind={MEDDPICCSelection.IdentifyPain}
            />
            <SaleforceUpdate
                kind={MEDDPICCSelection.Champion}
                suggestedValue={"Cost reduction (25%), ease of implementation (2-week timeline), and compliance requirements"}
            />
            <SaleforceUpdate
                kind={MEDDPICCSelection.Competition}
                suggestedValue={"Cost reduction (25%), ease of implementation (2-week timeline), and compliance requirements"}
            /> 
        </div>
    );
};

export {
    SalesforceUpdates
};