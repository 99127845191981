import { useState } from 'react';
import styles from './CallPage.module.css';
import { useNavigate } from 'react-router-dom';
import { FollowUpContainer } from './FollowUps/FollowUpContainer';
import { PreCallContainer } from './PreCall/PreCallContainer';
import { PostCallContainer } from './PostCall/PostCallContainer';
import { LiveCallContainer } from './LiveCall/LiveCallContainer';

enum Page {
    PreCall,
    LiveCall,
    PostCall,
    FollowUp,
}

const CallPage = () => {

    const [page, setPage] = useState<Page>(Page.PreCall);
    const navigate = useNavigate();

    return (
        <div className={styles.callPageContent} >
            <div className={styles.precallpageContainer}>
                <div className={styles.topNavContainer}>
                    <div className={styles.backItem} onClick={() => navigate('/calls')}>
                        Back
                    </div>
                    <div 
                        className={styles.prePostContainer} 
                        >
                        <div 
                            onClick={() => setPage(Page.PreCall)}
                            className={page == Page.PreCall ? `${styles.preCallItem} ${styles.prePostActive}`: styles.preCallItem}
                        >Pre-Call</div>
                        <div 
                            onClick={() => setPage(Page.LiveCall)}
                            className={page == Page.LiveCall ? `${styles.preCallItem} ${styles.prePostActive}`: styles.preCallItem}
                        >Live Call</div>
                        <div 
                            onClick={() => setPage(Page.PostCall)}
                            className={page == Page.PostCall ? `${styles.preCallItem} ${styles.prePostActive}`: styles.preCallItem}
                        >Post-Call</div>
                        <div
                            onClick={() => setPage(Page.FollowUp)}
                            className={page == Page.FollowUp ? `${styles.preCallItem} ${styles.prePostActive}`: styles.preCallItem}
                        >Follow Up</div>
                    </div>
                </div>
                {
                    page == Page.PreCall && <PreCallContainer/>
                }
                {
                    page == Page.LiveCall && <LiveCallContainer/>
                }
                {
                    page == Page.PostCall && <PostCallContainer/>
                }
                {
                    page == Page.FollowUp && <FollowUpContainer/>
                }
            </div>
        </div>
    );
};

export {
    CallPage,
};