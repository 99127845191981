import styles from './ContentCardLibraryPage.module.css';

import { ReactComponent as ArticulateValuePropositionIcon } from '../../assets/diamond.svg';
import { ReactComponent as PotentialObjectionsIcon } from '../../assets/geometry.svg';
import { ReactComponent as CustomerStoryIcon } from '../../assets/open-book.svg';
import { ReactComponent as SuggestedNextStepsIcon } from '../../assets/next.svg';
import { ReactComponent as PotentialPainPointsIcon } from '../../assets/notification.svg';
import { ReactComponent as DiscoveryQuestionIcon } from '../../assets/loupe.svg';
import { ContentCard, ContentCardType } from '../../ContentCard/ContentCard';

type ContentCardHeaderProp = {
    cardType: ContentCardType,
}

const ContentCardHeader = ({cardType}: ContentCardHeaderProp) => {
    if(cardType === ContentCardType.DiscoveryQuestion){
        return <div className={styles.contentCardSectionHeader}>
            <DiscoveryQuestionIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardSectionName}> Discovery Question </div>
        </div>
    }
    if(cardType === ContentCardType.PotentialPainPoints){
        return <div className={styles.contentCardSectionHeader}> 
            <PotentialPainPointsIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardSectionName}> Potential Pain Points </div>
        </div>
    }
    if(cardType === ContentCardType.ArticulateValueProposition){
        return <div className={styles.contentCardSectionHeader}>
            <ArticulateValuePropositionIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardSectionName}> Articulate value proposition </div>
        </div>
    }
    if(cardType === ContentCardType.CustomerStories){
        return <div className={styles.contentCardSectionHeader}>
            <CustomerStoryIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardSectionName}> Customer stories </div>
        </div>
    }
    if(cardType === ContentCardType.PotentialObjections){
        return <div className={styles.contentCardSectionHeader}> 
            <PotentialObjectionsIcon className={styles.contentCardIcon}/>
            <div className={styles.contentCardSectionName}> Potential Objections </div>
        </div>
    }
    return <div className={styles.contentCardSectionHeader}> 
        <SuggestedNextStepsIcon className={styles.contentCardIcon}/>
        <div className={styles.contentCardSectionName}> Suggested next steps </div>
    </div>
}

type ContentCardSectionProp = {
    cardType: ContentCardType,
}

const ContentCardSection = ({cardType}: ContentCardSectionProp) => {
    return <div className={styles.contentCardSection}>
        <ContentCardHeader cardType={cardType}/>
        <div className={styles.contentCards}>
            <ContentCard data={
                {
                    lastSeen: 1730772238,
                    isActive: true,
                    title: "Initial Pain Discovery",
                    adantages: ["Unanswered rental inquiries impact", "Wasted marketing spend", "Staff productivity drain"],
                    talkTrack: "What percentage of your rental inquiries receive responses within an hour? Most properties see 50% go unanswered.",
                }
            } updateContentCard={(newData) => {}}/>
            <ContentCard data={
                {
                    lastSeen: 1730772238, 
                    isActive: true, 
                    title: "ROI Exploration",
                    adantages: ["Tour booking increase", "Marketing efficiency boost", "Staff time savings"],
                    talkTrack: "What percentage of your rental inquiries receive responses within an hour? Most properties see 50% go unanswered."
                }
            } updateContentCard={(newData) => {}}/>
            <ContentCard data={
                {
                    lastSeen: 1730772238, 
                    isActive: true, 
                    title: "Technical Depth Discovery",
                    adantages: ["Tour booking increase", "Marketing efficiency boost", "Staff time savings"],
                    talkTrack: "What percentage of your rental inquiries receive responses within an hour? Most properties see 50% go unanswered."
                }
            
            } updateContentCard={(newData) => {}}/>
            <ContentCard data={
                {
                    lastSeen: 1730772238, 
                    isActive: true,
                    title: "Technical Depth Discovery",
                    adantages: ["Tour booking increase", "Marketing efficiency boost", "Staff time savings"],
                    talkTrack: "What percentage of your rental inquiries receive responses within an hour? Most properties see 50% go unanswered." 
                }
            } updateContentCard={(newData) => {}}/>
            <ContentCard data={
                {
                    lastSeen: 1730772238, 
                    isActive: true,
                    title: "Technical Depth Discovery",
                    adantages: ["Tour booking increase", "Marketing efficiency boost", "Staff time savings"],
                    talkTrack: "What percentage of your rental inquiries receive responses within an hour? Most properties see 50% go unanswered."                
                }
            } updateContentCard={(newData) => {}}/>
        </div>
    </div>
}

const ContentCardLibraryPage = () => {

    return (
        <div className={styles.callPageContent} >
            <div className={styles.contentcardSection}>
                <div className={styles.contentcardSectionTitle}>Active Content Cards</div>
                <ContentCardSection cardType={ContentCardType.DiscoveryQuestion}/>
                <ContentCardSection cardType={ContentCardType.PotentialPainPoints}/>
                <ContentCardSection cardType={ContentCardType.ArticulateValueProposition}/>
                <ContentCardSection cardType={ContentCardType.CustomerStories}/>
                <ContentCardSection cardType={ContentCardType.PotentialObjections}/>
                <ContentCardSection cardType={ContentCardType.SuggestedNextSteps}/>
            </div>
        </div>
    );
};

export { 
    ContentCardLibraryPage
};