import styles from './Transcript.module.css';
import { ProgressBarFilter } from '../../../VideoPlayer/VideoPlayer';

type TranscriptItem = {
    timestamp: string,
    name: string,
    quote: string,
}

const transcript: TranscriptItem[] = [
    { timestamp: "00:00", name: "Remy", quote: "Hi Hugo, thank you for taking the time to speak with me today. How’s your day going?" },
    { timestamp: "00:05", name: "Hugo", quote: "Hi, Remy! It’s been good, thanks for asking. Looking forward to learning more about what you offer." },
    { timestamp: "00:10", name: "Remy", quote: "I’m glad to hear it. To start, I’d love to learn a bit about your role and what you’re currently working on so I can make sure our conversation is as relevant as possible." },
    { timestamp: "00:15", name: "Hugo", quote: "Sure! I’m the operations manager here at Taylor. My team oversees logistics and helps streamline our processes across departments." },
    { timestamp: "00:25", name: "Remy", quote: "That’s great, sounds like you’re juggling quite a few important tasks! What are some of the challenges you’re currently facing in your role?" },
    { timestamp: "00:30", name: "Hugo", quote: "Well, we’re dealing with some inefficiencies in how we manage our projects. A lot of our communication is siloed, and it’s leading to delays. We’re exploring tools to centralize and improve collaboration." },
    { timestamp: "00:40", name: "Remy", quote: "I completely understand how frustrating that can be. A lot of companies I work with face similar issues, and that’s actually one of the main areas our solution focuses on—helping teams collaborate more efficiently and reduce the back-and-forth that slows everything down." },
    { timestamp: "00:50", name: "Hugo", quote: "That sounds interesting. How does your solution help with that?" },
    { timestamp: "00:55", name: "Remy", quote: "Our platform integrates with the tools you're already using, and centralizes communication, task management, and documentation. It allows you to track project progress, streamline team discussions, and automate some of the repetitive workflows that can eat up time. We’ve seen companies significantly cut down on time spent searching for information or coordinating between departments." },
    { timestamp: "01:10", name: "Hugo", quote: "That does sound like it could help us. But we’re also a bit cautious with adopting new tools. We don’t want to disrupt what’s working, you know?" },
    { timestamp: "01:20", name: "Remy", quote: "Absolutely, and that’s a valid concern. We focus on seamless integrations, so the transition is as smooth as possible. Many of our clients were able to roll out the platform with minimal disruption, and we provide full support during the setup phase. We can even do a pilot to see how it fits with your team before making a full commitment." },
    { timestamp: "01:40", name: "Hugo", quote: "I see. And how easy is it for our team to learn the platform? We don’t have a lot of bandwidth for training." },
    { timestamp: "01:45", name: "Remy", quote: "That’s one of the key benefits of our solution—it's designed to be intuitive. Our onboarding process is self-paced, with plenty of resources to guide your team, and we provide live support if needed. Plus, the platform is flexible, so you can adjust it based on how your team prefers to work." },
    { timestamp: "02:05", name: "Hugo", quote: "That sounds promising. How long does it typically take for companies to see results?" },
    { timestamp: "02:10", name: "Remy", quote: "Most companies start seeing improvements within the first couple of weeks after adoption, especially when it comes to collaboration and time-saving on administrative tasks. However, it can take a few months to fully realize the longer-term benefits, like project efficiency and team alignment." },
    { timestamp: "02:30", name: "Hugo", quote: "That makes sense. So, how does the pricing work for your platform?" },
    { timestamp: "02:35", name: "Remy", quote: "We offer a subscription model based on the number of users and the features you need. We’d start with a conversation about your specific needs to recommend the best package, and we’re very transparent about costs—no hidden fees." },
    { timestamp: "02:55", name: "Hugo", quote: "Alright, I appreciate the clarity. I’m curious—how do you ensure that the platform stays up-to-date and continues to meet our needs over time?" },
    { timestamp: "03:05", name: "Remy", quote: "Great question. We have a dedicated product team that regularly updates the platform based on user feedback and emerging industry trends. We also have a customer success team that checks in with you regularly to ensure you're getting the most out of the platform, and they help you with any adjustments or new features that might benefit your team." },
    { timestamp: "03:25", name: "Hugo", quote: "That’s good to know. Can you walk me through a specific example of how this has worked for a company similar to ours?" },
    { timestamp: "03:35", name: "Remy", quote: "Sure! One example is a logistics company we worked with that had challenges with coordinating between warehouse teams and their dispatch department. They adopted our platform, and within a month, they reduced project delays by 30% and improved team satisfaction with better communication. They also saved several hours a week on manual data entry, thanks to our automation tools." },
    { timestamp: "04:00", name: "Hugo", quote: "That’s impressive. I like the idea of reducing manual work." },
    { timestamp: "04:05", name: "Remy", quote: "Yeah, automation can be a game-changer. It helps people focus on higher-value work rather than getting bogged down in administrative tasks." },
    { timestamp: "04:15", name: "Hugo", quote: "I agree. Do you offer any kind of trial period to test things out before committing?" },
    { timestamp: "04:20", name: "Remy", quote: "Yes, we offer a 30-day free trial for companies to explore the platform. During that time, we provide full support to help you get set up and make sure you’re getting the most out of it." },
    { timestamp: "04:35", name: "Hugo", quote: "A trial sounds great. I’ll need to discuss this with my team first, but it definitely seems like it could be a good fit." },
    { timestamp: "04:45", name: "Remy", quote: "Absolutely, I’d be happy to set up a demo or send over more detailed information for you to review with your team. Would next week be a good time to schedule that?" },
    { timestamp: "04:55", name: "Hugo", quote: "Next week works. Let’s aim for Tuesday afternoon." },
    { timestamp: "05:00", name: "Remy", quote: "Perfect, I’ll send over an invite for Tuesday at 2 PM. We can walk through the platform in more detail, and I’ll answer any other questions you might have." },
    { timestamp: "05:10", name: "Hugo", quote: "Sounds good, Remy. Thanks for taking the time to talk today." },
    { timestamp: "05:15", name: "Remy", quote: "Of course, Hugo! I’m looking forward to continuing our conversation. Have a great rest of your day." }
];


type TranscriptExampleProp = {
}

const TranscriptExample = (prop: TranscriptExampleProp) => {
    return (
        <div className={styles.transcriptScriptContainer}>
            {
                transcript.map((item) => {
                    return <div className={styles.transcriptItem} key={item.timestamp}>
                        <div className={styles.transcriptItemHeader}>
                            {`${item.timestamp} ${item.name}`}
                        </div>
                        <div className={styles.transcriptItemContent}>
                            {item.quote}
                        </div>

                    </div> 
                })
            }
        </div>
    )
}


type TimestampSelectionProp = {

}

const Transcript = (prop: TimestampSelectionProp) => {
    return (
        <div className={styles.transcriptContainer}>
            <div className={styles.transcriptScriptParentContainer}>
                <TranscriptExample/>
            </div>
            <div className={styles.transcriptSearchBox}>
                <input
                    className={styles.transcriptSearchBoxInput}
                    placeholder='Search'
                />
                <button className={styles.transcriptSearchBoxButton}>
                    Find
                </button>
            </div> 
        </div>
    )

}

export {
    Transcript
}